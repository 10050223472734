<template>
  <div class="organization">
    <div class="organization__wrapper">
      <h1 class="organization__title">Заявка № ТА{{ id }}</h1>
      <small class="cursor-pointer text-teal-500" @click="$router.back()">Назад</small>

      <div v-if="!user.uf_telegram_id"
           class="p-4 my-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
           role="alert">
        <span class="font-medium">Внимание!</span> Ваш аккаунт не привязан к телеграм-боту, где вы можете получать
        уведомления.
        Привязать учетную запись можно
        <router-link class="text-blue-500" to="/user/dashboard/user-edit">здесь.</router-link>
      </div>


      <div class="organization__form-section">
        <h3 class="mb-5">Паспортные данные</h3>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div>
            <label>
              Тип документа*
              <Autocomplete
                  disabled
                  v-model="form.uf_passport_doctype"
                  :items="docTypes"
                  search-by="code"
                  item-text="name"
                  item-value="code"/>
            </label>
            <label>
              Фамилия*
              <input disabled type="text" placeholder="" v-model="form.uf_passport_surname">
            </label>

            <label for="">
              Имя*
              <input disabled type="text" v-model="form.uf_passport_name">
            </label>
            <label for="">
              Отчество
              <input disabled type="text" placeholder="" v-model="form.uf_passport_middlename">
            </label>

            <label for="">
              Дата рождения*
              <input disabled type="date" v-model="form.uf_passport_birthday">
            </label>

            <label for="">
              Серия и номер паспорта*
              <input disabled type="text" v-model="form.uf_passport_number">
            </label>

            <label for="">
              Дата выдачи*
              <input disabled type="date" v-model="form.uf_passport_date_on">
            </label>

            <label for="">
              Дата окончания*
              <input disabled type="date" v-model="form.uf_passport_date_off">
            </label>
            <label for="">
              Идентификационный номер*
              <input disabled type="text" v-model="form.uf_passport_person_id">
            </label>

          </div>
          <div>
            <label for="">
              Почтовый индекс
              <input disabled type="text" v-model.trim="form.uf_passport_index">
            </label>
            <label for="">
              Область
              <input disabled type="text" v-model.trim="form.uf_passport_region">
            </label>

            <label>
              Город*
              <input disabled type="text" v-model.trim="form.uf_passport_city">
            </label>


            <label>
              Улица*
              <input disabled type="text" v-model.trim="form.uf_passport_street">
            </label>

            <label>
              Дом*
              <input disabled type="text" v-model.trim="form.uf_passport_house">

            </label>

            <label for="">
              Помещение
              <input disabled type="text" placeholder="" v-model.trim="form.uf_passport_room">
            </label>
          </div>


        </div>
      </div>

      <div class="organization__form-section w-full lg:w-1/3">
        <h3 class="mb-5">Льготы</h3>
        <label for="">
          Наличие льготы *
          <Autocomplete
              disabled
              v-model="form.uf_licence_type"
              :items="licenceTypes"
              search-by="name"
              item-text="name"
              item-value="value"/>
        </label>


      </div>

      <div class="organization__form-section">
        <h3 class="mb-5">Информация о ТС</h3>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div>


            <label for="">
              Марка* (если отсутствует - "прочие")
              <Autocomplete
                  disabled
                  v-model="form.uf_brand"
                  :items="brandsList"
                  search-by="name"
                  item-text="name"
                  item-value="name"/>
            </label>
            <label for="">
              Модель *
              <input disabled type="text" v-model.trim="form.uf_model">

            </label>
            <label for="">
              VIN ТС *
              <input disabled type="text" v-model.trim="form.uf_vin">

            </label>
            <label>
              Категория ТС *
              <Autocomplete
                  disabled
                  v-model="form.uf_vehicle_category"
                  :items="categoriesList"
                  search-by="code"
                  item-text="text"
                  item-value="code"/>
            </label>


            <label for="">
              Тип ТС *
              <Autocomplete
                  disabled
                  v-model="form.uf_vehicle_type"
                  :items="typesList"
                  search-by="name"
                  item-text="name"
                  item-value="code"/>
            </label>


            <label for="">
              Тип двигателя *
              <Autocomplete
                  disabled
                  v-model="form.uf_vehicle_engine_type"
                  :items="engineTypes"
                  search-by="name"
                  item-text="name"
                  item-value="value"/>
            </label>


          </div>
          <div>
            <label for="">
              Стоимость ТС *
              <input disabled type="text" :value="form.uf_cost">

            </label>
            <label for="">
              Валюта инвойса (Валюта счёта)» *
              <Autocomplete
                  disabled
                  v-model="form.uf_cost_currency"
                  :items="currenciesList"
                  search-by="search"
                  item-text="text"
                  item-value="letterCode"/>
            </label>


            <label for="">
              Объем двигателя (см3)
              <input
                  disabled
                  type="text" :value="form.uf_volume">
            </label>
            <label for="">
              Момент выпуска * <span
                v-tooltip="'Если нет информации о дате выпуска, необходимо ввести 15 число;<br> Если нет информации о дате и месяце выпуска, необходимо ввести 1 июля'">
                            <i class="fas fa-info-circle"></i>
                        </span>
              <input type="date" v-model.trim="form.uf_date">
            </label>

            <label>
              Кол-во мест * <span v-tooltip="'Количество мест для сидения, помимо места для водителя'">
                            <i class="fas fa-info-circle"></i>
                        </span>
              <input type="text" :value="form.uf_place_count">

            </label>
            <label>
              Масса (т)
              <input type="text" :value="form.uf_weight">
            </label>


            <label for="">
              Номер ПТО * (либо ТС не на ПТО)
              <Autocomplete
                  disabled
                  v-model="form.uf_pto"
                  :items="ptoList"
                  search-by="name"
                  item-text="name"
                  item-value="value"/>
            </label>
          </div>
        </div>

      </div>

      <div class="organization__form-section">
        <div class="sm:w-full lg:w-1/3">
          <label>
            Контактный номер* (+375 ...)
            <input disabled type="text" v-model.trim="form.uf_contact_phone">
          </label>
        </div>
      </div>


    </div>

  </div>
</template>
<script>

import 'vue2-dropzone/dist/vue2Dropzone.min.css'


import axios from 'axios'
import {mapGetters} from 'vuex'
import Autocomplete from "@/components/shared/Autocomplete.vue";

export default {
  components: {Autocomplete},
  data: () => ({
    id: null,
    loading: false,
    ptoList: [
      {
        name: "06649 - ПТО \"Белтаможсервис-ТЛЦ\"",
        value: "06649"
      },
      {
        name: "06650 - ТЛЦ \"Минск-Белтаможсервис-2\" ",
        value: "06650"
      },
    ],
    brandsList: [],
    typesList: [],
    categoriesList: [],
    currenciesList: [],
    docTypes: [],
    licenceTypes: [
      {
        name: 'без льготы',
        value: 'без льготы',
      },
      {
        name: 'удостоверение инвалида',
        value: 'удостоверение инвалида',
      },
      {
        name: 'удостоверение многодетной семьи',
        value: 'удостоверение многодетной семьи',
      }
    ],
    engineTypes: [
      {
        name: 'бензин (в т.ч. гибрид)',
        value: 'бензин',
      },
      {
        name: 'дизель (в т.ч. гибрид)',
        value: 'дизель',
      },
      {
        name: 'электромобиль',
        value: 'электромобиль',
      }
    ],
    form: {
      uf_country: '',
      uf_brand: '',
      uf_model: '',
      uf_vin: '',
      uf_cost: '',
      uf_volume: '',
      uf_date: '',
      uf_pto: '06650',
      uf_dealer_doc: '',
      uf_passport_surname: '',
      uf_passport_name: '',
      uf_passport_middlename: '',
      uf_passport_doctype: '',
      uf_passport_number: '',
      uf_passport_date_on: '',
      uf_passport_date_off: '',
      uf_passport_person_id: '',
      uf_passport_index: '',
      uf_passport_region: '',
      uf_passport_city: '',
      uf_passport_street: '',
      uf_passport_house: '',
      uf_passport_room: '',
      uf_licence_type: '',
      uf_vehicle_category: '',
      uf_vehicle_engine_type: '',
      uf_vehicle_type: '',
      uf_cost_currency: '',
      uf_place_count: null,
      uf_weight: null,
      uf_offer_agreement: false,
      uf_contact_phone: ''
    },
    passport: '',
    documents: '',
    payments: '',
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      dark: 'darkTheme'
    }),
  },
  created() {
    this.id = this.$route.params.id
    if (this.id) {
      axios.get(`https://declarant.by/rest/user/transport-declaration/${this.id}`).then(res => {
        const item = res.data.data
        Object.keys(item).forEach(key => {
          if (key in this.form) {
            this.form[key] = item[key]
          }
        })

      })
    }
    this.fetchBrandsList()
    this.fetchTransportTypes()
    this.fetchTransportCategories()
    this.fetchCurrencies()
    this.fetchDocTypes()
  },
  methods: {
    fetchDocTypes() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiIdentityDocuments').then(res => {
        this.docTypes = res.data?.list?.filter((item) => item.code.startsWith('BY')) ?? []
      })
    },
    fetchCurrencies() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiCurrencies').then(res => {
        this.currenciesList = res.data?.list.map(item => {
          return {
            ...item,
            text: `${item.letterCode} (${item.name})`,
            search: [item.letterCode, item.name, item.digitCode, item.ownName].join('-').toLowerCase(),
          }

        }) ?? []
      })
    },
    fetchTransportTypes() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiVehicleTypes').then(res => {
        this.typesList = res.data?.list?.filter((item) => item.code.startsWith('3')) ?? []
      })
    },
    fetchTransportCategories() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiTransportCategories').then(res => {
        this.categoriesList = res.data?.list?.filter((item) => !item.code.startsWith('S')) ?? []
        this.categoriesList = this.categoriesList.map((item) => {
          return {
            ...item,
            text: `${item.code} - ${item.name}`
          }

        })
      })
    },
    fetchBrandsList() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiTransportMarks').then(res => {
        this.brandsList = res.data?.list?.map(item => {
          return {
            ...item,
            search: [item.name, item.own_name].join('-')
          }
        }) ?? []
      })
    },

  },

}
</script>

<style scoped>
.tooltip {
  display: none !important;
}

i:hover + .tooltip {
  display: block;
}

.checkbox input {
  opacity: 0;
  position: absolute;
}

.checkbox input + label {
  display: inline-block;
  padding: 2px;
  margin: 2px 8px -2px 0;
  cursor: pointer;
  line-height: 22px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.checkbox input + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 6px;
  border-radius: 6px;
  height: 20px;
  width: 20px;
  border: 2px solid gray;

}

.checkbox input:checked + label {
  color: rgb(52, 211, 153);
}

.checkbox input:checked + label:before {
  background: rgb(52, 211, 153);
  border-color: rgb(52, 211, 153);
}

label {
  display: block;
  margin: 0 0 10px 0;
}

select {
  margin: 0 !important;
}


.organization {
  @apply my-10  mx-auto relative
  xl:container xl:px-5;
}

.organization__wrapper {
  @apply relative;
}

.organization__title {
  @apply text-3xl
}

.organization__nav {
  @apply flex justify-center py-5 w-full
  sm:flex-col
  xl:flex-row
}

.organization__nav-item {
  @apply border dark:border-gray-700 border-gray-300  w-full
  sm:p-2
  xl:p-7

}

.organization__nav-item-text {
  @apply text-2xl font-semibold
}

.organization__form-section {
  @apply py-5
}

.organization__form-section select {
  min-height: 44px !important;
}

.organization__form-files {
  @apply flex items-end my-5
  sm:flex-col
  xl:flex-row
}

.organization__file-input-wrapper {
  @apply sm:w-full
  xl:w-1/2 xl:mx-1
}

.organization__file-input-title {
  @apply text-xl mb-2
}

.organization__check-wrapper {
  @apply flex mb-1
  sm:flex-col
  xl:flex-row
}

.organization__check-group {
  @apply dark:bg-gray-800 bg-blue-100 flex items-center justify-center text-center
  sm:w-full sm:py-2
  xl:w-1/4 xl:py-0
}

input[type=text], select {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  rounded bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white;
}

.organization__dropzone {
  @apply dark:bg-gray-800 rounded bg-gray-300 dark:hover:border-gray-800 dark:border-gray-700
  sm:mb-10
  xl:mb-0
}

.organization__check-result {
  @apply
  sm:w-full
  xl:w-3/4
}

.organization__check-text {
  @apply px-3 py-1
}

.organization__rule {
  @apply px-5
}

.organization__actions-wrapper {
  @apply flex justify-center
}

.organization__action {
  @apply px-5 py-3 rounded
}

.file-loader {
  @apply absolute w-1/4 top-0 right-0 flex items-center rounded p-5 bg-blue-300 opacity-90 z-30;
}

select::-webkit-scrollbar {
  display: none;
}

.group-select:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}

.group-select:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

* >>> .dz-progress {
  display: none !important;
  opacity: 0 !important;
}

.datepicker {
  width: 100% !important;
}

input, .datepicker {
  max-height: 44px !important;
  margin: 0 !important;
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent rounded
  bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white
}

input[type="radio"] {
  width: inherit;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  border: 1px solid #2196F3;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 20px;
  height: 20px;
  position: relative;
}

.spinner .path {
  stroke: rgb(255, 255, 255);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


</style>