<template>
    <div class="autocomplete">
        <input v-model="search"
               :disabled="disabled" @focusin="focus = true" @focusout="onFocusOut" @keydown.enter="onEnter" :placeholder="placeholder" type="text"
               autocomplete="off">
        <ul v-if="show" class="results">
            <li @click="selectValue(item)" class="result" :key="idx" v-for="(item, idx) in filteredItems">
                {{ item[itemText] }}
            </li>
        </ul>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    props: {
        value: {
            required: true,
            type: String
        },
        placeholder: {
            type: String,
        },
        items: {
            type: Array,
            required: true,
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        searchBy: {
            type: String,
            required: true,
        },
        disabled:{
          type:Boolean,
          required:false,
        }
    },
    data: () => ({
        search: '',
        selected: '',
        focus:false
    }),
    computed: {
        ...mapGetters({
          dark: 'darkTheme'
        }),
        show() {
            return (this.search && this.search !== this.selected) || this.focus
        },
        filteredItems() {
            if (this.search) {
                return this.items.filter(i => {
                    return i[this.searchBy].toLowerCase().indexOf(this.search.toLowerCase()) !== -1
                })
            }
            return this.items
        }
    },
    watch: {
        value: {
            handler() {
                this.setDefault()
            },
            immediate: true,
        },
        items: {
            handler() {
                this.setDefault()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        setDefault() {
            if (this.items.length && this.value) {
                const item = this.items.find(i => i[this.itemValue] === this.value)
                this.selected = item[this.itemText]
                this.search = item[this.itemText]
            }
        },
        onEnter() {
            if (this.search && this.filteredItems.length) {
                const [value] = this.filteredItems
                this.selectValue(value)
            }
        },
        onFocusOut() {
            setTimeout(() => {
                if (this.selected !== this.search) {
                    this.search = ''
                    this.selected = ''
                    this.$emit('input', '')
                }
                this.focus = false
            }, 200)
        },
        selectValue(v) {
            this.selected = v[this.itemText]
            this.search = v[this.itemText]
            this.$emit('input', v[this.itemValue])
        }
    }
}
</script>
<style scoped>
input {
    max-height: 44px !important;
    margin: 0 !important;
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent rounded
    bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
}

.autocomplete {
    position: relative;
}

.results {
    @apply absolute z-10 block px-2 mt-1 h-auto max-h-56
    overflow-y-auto w-full dark:bg-gray-800 bg-gray-100 rounded
}

.result {
    @apply hover:text-blue-500 cursor-pointer list-none text-left p-1
}
</style>